<template>
  <div class="content">
    <PageHeader
      :title="
        isEdit
          ? $t('generic-str.lbl-edit-user')
          : $t('generic-str.lbl-new-user')
      "
    />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div
            v-if="
              isEdit &&
              !(
                $store.state.account.role == 'admin' ||
                $store.state.account.role === 'owner'
              )
            "
          >
            {{ $t('warnings.user.not-edit') }}
          </div>
          <form
            @submit.prevent="request"
            class="card"
            v-else-if="fetchedDerpartments && fetchedUser"
          >
            <div class="card-body block-el">
              <div class="form-body">
                <div class="form-group">
                  <label class="control-label">Email</label>
                  <input
                    v-model="form.email"
                    type="email"
                    class="form-control"
                    :readonly="isEdit"
                    required
                  />
                </div>
                <div class="form-group" v-if="isEdit">
                  <label class="control-label">{{
                    $tc('generic-str.name', 1)
                  }}</label>
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <template v-if="$store.state.plans.omniPlan">
                  <div class="form-group departments" v-if="departments.length">
                    <label class="control-label">{{
                      $t('generic-str.department')
                    }}</label>
                    <template v-for="(department, index) in departments">
                      <div :key="index" v-if="department.active">
                        <!-- <input type="radio" name="department" :id="department.id" v-model="currentDepartament"> -->
                        <input
                          type="radio"
                          name="department"
                          :id="department.id"
                          :value="department.id"
                          v-model="form.department"
                          :checked="form.department === department.id"
                        />
                        <label :for="department.id">
                          <div>
                            <i class="far fa-circle"></i>
                            <i class="far fa-check-circle"></i>
                          </div>
                          <!-- <div v-if="department.name == 'Financeiro'">
                          <h4>{{$t('home.invite-card.select.fin')}}</h4>
                          <p>{{department.description}}</p>
                        </div>
                        <div v-else-if="department.name == 'Marketing'">
                          <h4>{{department.name}}</h4>
                          <p>{{$t('home.invite-card.description.mkt')}}</p>
                        </div>
                        <div v-else-if="department.name == 'Comercial'">
                          <h4>{{$t('home.invite-card.select.commercial')}}</h4>
                          <p>{{$t('home.invite-card.description.commercial')}}</p>
                        </div>
                        <div v-else-if="department.name == 'Onboarding'">
                          <h4>{{department.name}}</h4>
                          <p>{{$t('home.invite-card.description.onboarding')}}</p>
                        </div>
                        <div v-else-if="department.name == 'Desenvolvimento'">
                          <h4>{{$t('home.invite-card.select.dev-2')}}</h4>
                          <p>{{$t('home.invite-card.description.dev-2')}}</p>
                        </div> -->
                          <div>
                            <h4>{{ department.name }}</h4>
                            <p>{{ department.description }}</p>
                          </div>
                        </label>
                      </div>
                    </template>
                  </div>
                  <div class="form-group">
                    <label class="control-label">{{
                      $t('users.func.lbl-agent')
                    }}</label>
                    <!-- {{form.is_agent ? 1 : 0}} -->
                    <!-- <pre>{{JSON.stringify(this.$store.state.auth)}}</pre> -->
                    <div>
                      <input
                        class="
                          tgl tgl-light tgl-primary
                          department-modal-change
                        "
                        id="live-active"
                        type="checkbox"
                        v-model="form.is_agent"
                        :checked="form.is_agent"
                      />
                      <label class="tgl-btn" for="live-active"></label>
                    </div>
                  </div>
                  <div class="form-group" v-if="isEdit && form.is_agent">
                    <label class="control-label">Máximo de atendimentos</label>
                    <input
                      v-model="form.max_calls_at_time"
                      type="tel"
                      class="form-control"
                      required
                      @blur="validateMaxCalls()"
                    />
                  </div>
                </template>
                <!--
                <div class="form-group departments" v-else>
                  <label class="control-label">Departamento</label>
                  <div class="missing">
                    Não há departmentos cadastrado, você pode cadastrar um agora:<br>
                    <router-link to="/departments" class="btn btn-secondary" aria-expanded="false">
                      Criar departmento
                    </router-link>
                  </div>
                </div>
                -->
                <div class="form-group" v-if="form.role !== 'owner'">
                  <label class="control-label">{{
                    $t('users.lbl-func')
                  }}</label>
                  <!-- <div>{{userId}} = {{$store.state.account.id}}</div> -->
                  <select-input
                    v-model="form.role"
                    :reduce="(roles) => roles.value"
                    :options="roles"
                    :clearable="false"
                    required
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!form.role"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </select-input>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'admin'"
                  >
                    Permissão total da plataforma, com exceção da contratação de
                    produtos ou pacotes de serviço.
                  </div>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'developer'"
                  >
                    Permissão de configurar webhook, widget (Yuper Chat),
                    analise de relatórios da plataforma.
                  </div>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'billing'"
                  >
                    Permissão a todos os relatórios e área financeira da
                    plataforma.
                  </div>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'user'"
                  >
                    Ver relatórios, interagir com os canais. (Chat) <!-- (Omni Business) -->
                    Atendimento.
                  </div>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'security'"
                  >
                    Antifraude Gestão.
                  </div>
                  <div
                    class="alert alert-info"
                    style="margin-top: 10px"
                    v-if="form.role == 'sales'"
                  >
                    Relatórios Gestão.
                  </div>
                  <p style="font-size: 10px">
                    * Todos os níveis de usuários podem ser agentes do Omni
                    Business
                  </p>
                </div>
              </div>
              <p v-if="!isEdit">
                {{ $t('users.description')
                }}<!-- Abaixo estão os usuários da sua conta. Depois de adicionar um usuário, a Yup Chat enviará um convite por email. Eles devem aceitar o convite para ter acesso à sua conta. -->
              </p>
            </div>
            <div class="modal-footer">
              <router-link
                to="/users"
                class="btn btn-secondary"
                aria-expanded="false"
              >
                {{ $t('generic-str.cancel') }}
              </router-link>
              <button
                type="submit"
                class="btn btn-primary"
                :class="{
                  'qt-loader qt-loader-mini qt-loader-right': isSending,
                }"
                :disabled="isSending"
              >
                {{
                  isEdit
                    ? $t('generic-str.update')
                    : $t('home.invite-card.btn-invite')
                }}
              </button>
            </div>
          </form>
          <div v-else class="qt-block-ui"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DepartmentService from '@/services/department.service';
import UserService from '@/services/user.service';
import PageHeader from '@/components/PageHeader.vue';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
    SelectInput,
  },
  data() {
    return {
      isEdit: false,
      fetchedDerpartments: false,
      fetchedUser: false,
      departments: [],
      // roles: [
      //   {
      //     label: this.$t('users.func.lbl-manager'),
      //     value: 'admin',
      //   },
      //   {
      //     label: this.$t('users.func.lbl-dev'),
      //     value: 'developer',
      //   },
      //   {
      //     label: this.$t('users.func.lbl-fin'),
      //     value: 'billing',
      //   },
      //   {
      //     label: this.$t('users.func.lbl-analyst'),
      //     value: 'user',
      //   },
      //   this.$store.state.account.whitelabel_id == null
      //     ? { label: this.$t('users.func.lbl-info-security'), value: 'security' }
      //     : null,
      //   this.$store.state.account.whitelabel_id == null
      //     ? { label: this.$t('users.func.lbl-exec-sale'), value: 'sales' }
      //     : null,
      // ],
      form: {
        name: null, // Convite não envia nome
        email: '',
        role: 'user',
        department: null,
        is_agent: false,
        max_calls_at_time: 10,
      },
      isSending: false,
      // currentDepartament: null,
    };
  },
  mounted() {
    const path = this.$router.currentRoute.path;

    this.userId = path.substring(12);
    this.isEdit = path.indexOf('/users/edit/') === 0;

    this.getDepartaments();

    if (this.isEdit) {
      this.getUser();
    } else {
      this.fetchedUser = true;
    }
  },
  computed: {
    roles() {
      const data = [
        {
          label: this.$t('users.func.lbl-manager'),
          value: 'admin',
        },
        {
          label: this.$t('users.func.lbl-dev'),
          value: 'developer',
        },
        {
          label: this.$t('users.func.lbl-fin'),
          value: 'billing',
        },
        {
          label: this.$t('users.func.lbl-analyst'),
          value: 'user',
        },
      ];

      if (this.$store.state.account.whitelabel_id === null) {
        data.push({
          label: this.$t('users.func.lbl-info-security'),
          value: 'security',
        });
        data.push({
          label: this.$t('users.func.lbl-exec-sale'),
          value: 'sales',
        });
      }

      return data;
    },
  },
  methods: {
    validateMaxCalls() {
      const max = this.form.max_calls_at_time;

      if (max < 1) {
        this.form.max_calls_at_time = 1;
      } else if (max > 100) {
        this.form.max_calls_at_time = 100;
      }
    },
    getDepartaments() {
      this.fetchedDerpartments = false;
      DepartmentService.getDepartments({}).then(
        (response) => {
          this.fetchedDerpartments = true;
          this.departments = response.data;
          // this.departments = [1, 2, 3, 4];
        },
        (error) => {
          this.fetchedDerpartments = true;
          this.departments = [];
          this.content = error;
        },
      );
    },
    getUser() {
      this.fetchedUser = false;
      UserService.getUser(this.userId).then((response) => {
        this.fetchedUser = true;
        this.form.name = response.name;
        this.form.email = response.email;
        this.form.department = response.department
          ? response.department.id
          : null;
        this.form.is_agent = response.pivot.is_agent;

        if (response.pivot.max_calls_at_time) {
          this.form.max_calls_at_time = response.pivot.max_calls_at_time;
        }

        this.form.role = response.pivot.role;
      },
      (error) => {
        this.fetchedUser = true;
        this.content = error;
      });
    },
    request() {
      if (this.isEdit) {
        this.edit(this.userId);
      } else {
        this.invite();
      }
    },
    edit(id) {
      this.isSending = true;

      this.validateMaxCalls();

      const data = {
        department: this.form.department,
        is_agent: this.form.is_agent,
        max_calls_at_time: this.form.max_calls_at_time,
      };

      if (this.form.name) data.name = this.form.name;

      if (this.form.role !== 'owner') data.role = this.form.role;

      UserService.updateUser(id, data).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Usuário atualizado',
            type: 'success',
          });
          // this.$emit('sent');
          this.isSending = false;
          // this.hide();
          this.$router.push('/users');
          this.$live.sync();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
    invite() {
      this.isSending = true;
      UserService.invite(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Convite enviado',
            type: 'success',
          });
          // this.$emit('sent');
          this.isSending = false;
          // this.hide();
          this.$router.push('/users');
          this.$live.sync();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>
<style scoped lang="scss">
tbody tr {
  &:hover {
    background-color: #fafafa; /* #f0f6ff */
  }
}

.control-label {
  font-weight: bold;
}

.form-group {
  max-width: 392px;

  &.departments {
    max-width: 520px;

    h4 {
      font-size: 11pt;
      font-weight: bold;
    }

    .row {
      margin: 0 0 1em 0;
    }

    .fa-circle,
    .fa-check-circle {
      font-size: 14pt;
    }

    input {
      visibility: hidden;
      position: fixed;
      left: -999px;
      top: -999px;
      pointer-events: none;
    }

    input + label {
      border-radius: 0.4em;
      border: thin solid #dfdfdf;
      padding: 1em 1em 1em 0;
      min-height: 120px;
      transition: 0.2s ease;
      width: 100%;

      & > div:first-child {
        text-align: center;
        float: left;
        width: 50px;
      }

      & > div:first-child + div {
        margin-left: 50px;
      }

      h4,
      i {
        transition: 0.2s ease;
      }

      p {
        color: #aeaeae;
        line-height: 1;
      }

      .fa-circle {
        display: block;
      }

      .fa-check-circle {
        display: none;
      }
    }

    input:checked + label {
      border-color: #752de6;
      h4,
      i {
        color: #752de6;
      }

      .fa-circle {
        display: none;
      }

      .fa-check-circle {
        display: block;
      }
    }
  }
}

.card-body {
  padding: 1.5em;
}
</style>
